<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="@/assets/images/logo/logo.png" />
    </Header>
    <!-- End Header Area -->

     
    <!-- Start Column Area  -->
    <div class="rn-column-area rn-section-gap bg_color--1 mt--100">
        <v-container>
            <center><h3 class="mb--100"><b>Οι κάρτες μου</b></h3></center>

             <div v-if="loyaltyCards.loyalty_cards.length == 0">
              Δεν υπάρχουν δεδομένα
            </div>
              <div class="overflow-auto" v-else  style="width: 100%;">
                <b-table id="loyaltyCardsList" show-loading large :fields="fields" :items="loyaltyCards.loyalty_cards" :per-page="this.loyaltyCards.pagination.items_per_page"
                   class="elevation-1 "
                  stripped responsive="xl">  
                    <template #cell(image)="data">
                       <div class="image">
                          <img v-if="data.item.showFront" @click="changeToBack(data.item)" src="@/assets/images/brand/frontCard.png">
                          <img v-else @click="changeToFront(data.item)" src="@/assets/images/brand/backCard.png">
                          <p v-if="data.item.showFront" class="cardNum">{{ data.item.card_number }}</p>
                          <p v-else class="cardCode">XXX</p>
                      </div>
                    </template>
                  </b-table>
              </div>
               <b-pagination
                    class="mt--100"
                    v-if="this.loyaltyCards.pagination.total_pages > 1"
                    v-model="currentPage"
                    v-on:click.native="scrollToTop"
                    :per-page="this.loyaltyCards.pagination.items_per_page"
                    :total-rows="this.loyaltyCards.pagination.total"
                    aria-controls="loyaltyCardsList"
                    align="center"
                  ></b-pagination> 
        </v-container>
    </div>
    <!-- End Column Area  -->
    

    <Footer />
  </div>
</template>

<script>
  import Header from "@/components/header/Header";
  import Footer from "@/components/footer/FooterTwo";
  import axios from 'axios';
  import { mapMutations, mapActions, mapGetters } from "vuex";

  export default {
    name: 'loyaltyCards',
    components: {
      Header,
      Footer,
    },
    data() {
      return {        
        currentPage: 1,
        loading: true,
        fields: [
          // A column that needs custom formatting
          { key: 'image', label: '' }, 
          { key: 'card_number', label: 'Αριθμός Κάρτας' }, 
          { key: 'card_code', label: 'Κωδικός' },
        ],
      };
    },
    computed: {
       ...mapGetters (["loyaltyCards"]),
    },
    watch: {
      currentPage: async function (newQuestion, oldQuestion) {
        await this.getLoyaltyCards(newQuestion);
      }
    },
    async  created () {
       if(!this.loyaltyCards){
        this.$loading(true);
        await this.getLoyaltyCards(1);
        this.$loading(false);
      }else{
        await this.getLoyaltyCards(1);
      }
    },
    methods: {
      ...mapActions(["getLoyaltyCards"]),
       changeToBack(id){
        id.showFront=false;
      },
      changeToFront(id){
        id.showFront=true;
      },
      scrollToTop() {
        this.$vuetify.goTo(0);
      }
    },
  };
</script>
<style scoped>
.image { 
   position: relative; 
   width: 100%; /* for IE 6 */
}

.cardNum { 
    position: absolute;
    top: 86px;
    left: 110px;
    width: 100%;
}
.cardCode { 
    position: absolute;
    top: 100px;
    left: 114px;
    width: 100%;
}
.v-application img, body img {
    max-width: 220px;
    display: block;
}
</style>